<template>
    <div class="box">
        <h2>Crefo Listen für Einschränkung</h2>
        <div class="body">
            <table>
                <tbody>
                    <tr class="clickable-row" v-for="filteredlist in crefoFilteredLists" v-bind:key="filteredlist.id">
                        <td @click="load(filteredlist.id)">{{ filteredlist.label }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crefoFilteredLists: {
                total: 0,
                results: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/crefo/filteredlists").then(
                response => {
                    this.crefoFilteredLists = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Listen");
                }
            );
        },
        load(key) {
            this.$router.push({ name: "crefo_filtered_edit", params: { id: key } });
        }
    }
};
</script>
